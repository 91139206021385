import { Reviews } from "./reviews";
import { useRecoilState } from "recoil";
import { isFeedBackVisibleState } from "../../recoil/atom/isFeedBackVisible";
import MyTeam from "./myteam";
import Statistic from "./state";
import Services from "./services";
import Contacts from "./contacts";
import FadeContent from "../FadeContent";
import Logo from "../../images/logo-1.svg";
import Intro from "../../images/intro_1.webp";
import Intro_2 from "../../images/intro_2.webp";
import AnimatedContent from "../AnimatedContent";

const MainPage = () => {
  const [, setIsVisible] = useRecoilState(isFeedBackVisibleState);

  const openModal = () => {
    setIsVisible(true);
  };

  return (
    <div className="overflow-x-hidden">
      <div
        className="bg-[#1F3645] lg:h-[570px] h-[460px] absolute w-full top-0 left-0 -z-10"
        rel="canonical"
      />
      <div className="bg-[#C5C5C5] w-full fixed top-0 left-0 right-0 bottom-0 -z-20" />
      <section className="flex justify-center">
        <div className="relative w-fit h-fit rounded-[30px] overflow-hidden">
          <FadeContent
            blur={false}
            duration={500}
            easing="ease-out"
            initialOpacity={0}
          >
            <img
              src={Intro}
              alt="Pushkarenko Intro"
              className="lg:w-[1094px] w-[350px] lg:h-[660px] h-[468px] object-cover lg:object-[80%_25%] object-[60%_35%]"
            />
          </FadeContent>
          <div className="absolute top-0 left-0 w-full h-full">
            <div className="lg:w-[1094px] lg:h-[660px]">
              <AnimatedContent
                distance={150}
                direction="horizontal"
                reverse={true}
                config={{ tension: 80, friction: 20 }}
                initialOpacity={0.2}
                animateOpacity
                scale={1.1}
                threshold={0.2}
              >
                <div className="lg:pt-[50px] pt-[25px] pl-[42px]">
                  <img
                    src={Logo}
                    alt="Logo Pushkarenko"
                    className="lg:w-[352px] w-[200px] lg:h-[132px] filter lg:block hidden"
                  />
                </div>
              </AnimatedContent>
              <div className="flex flex-col items-end text-right">
                <div className="block md:hidden absolute bottom-0 bg-black opacity-30 w-full h-[200px] -z-0" />

                <AnimatedContent
                  distance={150}
                  direction="horizontal"
                  reverse={false}
                  config={{ tension: 80, friction: 20 }}
                  initialOpacity={0.2}
                  animateOpacity
                  scale={1.1}
                  threshold={0.2}
                >
                  <div className="lg:pr-[57px] pr-[24px] lg:pt-[118px] pt-[250px] text-[14px] z-20">
                    <div>
                      <h1 className="flex flex-col text-[#fff] font-raleway lg:text-[45px] font-normal lg:leading-[43px]">
                        Олександра <b>Пушкаренко</b>
                      </h1>
                    </div>
                    <div>
                      <span className="lg:w-[380px] w-[300px] flex flex-col lg:mt-[16px] text-[#fff] font-raleway lg:text-[19px] font-normal pt-2">
                        Практикуюча адвокатка • <br /> Підприємниця •<br />{" "}
                        Експертка Громадського центру • правосуддя Одеської
                        області <br /> Авторка юридичного блогу в Instagram •{" "}
                        <br /> з +30 тис. підписників
                      </span>
                    </div>
                  </div>
                </AnimatedContent>

                <div className="hidden md:block">
                  <button
                    onClick={openModal}
                    className="w-[208px] lg:mr-[57px] mr-[24px] realetive animate-pulse lg:w-[260px] lg:h-[52px] h-[30px] mt-[5px] bg-[#ffffff] text-[#000000] rounded-[32px] lg:text-[14px] text-[12px] font-normal leading-[18px] font-inter hover:scale-95 hover:bg-[#f6f6f6] transform transition-transform"
                  >
                    Умови та запис на консультацію
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="flex justify-center mt-[60px] mb-[106px] overflow-hidden"
        id="about"
        style={{ scrollMarginTop: "100px" }}
      >
        <div className="flex flex-col lg:flex-row lg:w-[1101px] w-[350px]  rounded-[30px] bg-[#C5C5C5]">
          <div className="flex flex-col text-[#000] font-raleway lg:w-[595px] py-[15px] pl-[20px] pr-[25px] lg:text-[20px] font-light lg:leading-[38px]">
            <span className="flex justify-center lg:text-[45px] text-[25px] lg:leading-[43px]">
              Про мене
            </span>
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={true}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <p className="lg:pt-[25px] pt-[5px]">
                Вітаю, я Олександра Пушкаренко, практикуюча адвокатка з міста
                Одеса, але успішно консультую українців з усього світу онлайн
                або офлайн в нашому місті.
              </p>
            </AnimatedContent>
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={true}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <p className="lg:pt-[20px] pt-[15px]">
                Криза 2022 року стала для мене можливістю надавати онлайн
                швидку, змістовну, а головне дієву допомогу українцям із
                найактуальніших питань сьогодення. Мною проведено 4000+ онлайн
                консультацій з питань перетин кордону чоловіками під час режиму
                воєнного стану, оформлення відстрочки під час мобілізації та
                правової допомоги військовим з питань проходження військової
                служби.
              </p>
            </AnimatedContent>
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={true}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <p className="lg:pt-[20px] pt-[15px]">
                Я є авторкою юридичного блогу в інстаграм з 30+ тис.
                підписниками, де я щоденно транслюю актуальну обстановку на
                юридичному фронті, публікую сотні відгуків від моїх клієнтів,
                які безмежно мене мотивують та надихають.
              </p>
            </AnimatedContent>
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={true}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <p className="lg:pt-[20px] pt-[15px]">
                Мої клієнти майже щодня перетинають кордон, оформлюються
                відстрочки, отримують консультації з питань проходження
                військової служби, тому мені є чим із вами поділитись.
              </p>
            </AnimatedContent>
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={true}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <p className="lg:pt-[20px] pt-[15px]">
                Зі мною доступно, стримано та результативно – якщо Ви розділяєте
                мої цінності, буду рада знайомству та надати правову допомогу.
              </p>
            </AnimatedContent>
          </div>
          <div className="flex items-center lg:pl-10 m-2">
            <AnimatedContent
              distance={150}
              direction="horizontal"
              reverse={false}
              config={{ tension: 80, friction: 20 }}
              initialOpacity={0.2}
              animateOpacity
              scale={1.1}
              threshold={0.2}
            >
              <img
                src={Intro_2}
                alt="Pushkarenko Intro 2"
                className="w-[441px] lg:h-[636px] object-cover rounded-[30px]"
              />
            </AnimatedContent>
          </div>
        </div>
      </section>

      <section>
        <MyTeam />
      </section>

      <section>
        <Statistic />
      </section>

      <section>
        <Services />
      </section>

      <section>
        <Reviews />
      </section>

      <section>
        <Contacts />
      </section>
    </div>
  );
};

export default MainPage;
