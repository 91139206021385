import { selector } from "recoil";
import { isFeedBackVisibleState } from "../atom/isFeedBackVisible";

export const isFeedBackVisibleValueState = selector({
  key: "isFeedBackVisibleValueState",
  get: ({ get }) => {
    const isFeedBackVisible = get(isFeedBackVisibleState);
    return isFeedBackVisible;
  },
});
