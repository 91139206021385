import { MapContainer, Popup, TileLayer, Marker } from "react-leaflet";
import Instagram from "../../images/iconoir_instagram_black.svg";
import FaceBook from "../../images/ic_round-facebook_black.svg";
import Telegram from "../../images/ic_baseline-telegram_black.svg";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

const Contacts = () => {
  const customIcon = new Icon({
    iconUrl: "marker-icon.svg",
    iconSize: [48, 48],
  });

  return (
    <div
      className="bg-[#C5C5C5] py-10 px-10 flex justify-center"
      id="contacts"
      style={{ scrollMarginTop: "100px" }}
    >
      <div className="max-w-[1440px] w-full">
        <div className="flex items-center justify-center">
          <h1 className="text-[#000] text-[45px] font-normal pb-8">Контакти</h1>
        </div>
        <div className="flex flex-col-reverse lg:flex-row justify-center">
          <div className="md:pr-10">
            <div className="border rounded-[30px] h-fit shadow-lg">
              <div className="p-5">
                <div className="pb-1 px-3">
                  <span className="text-black font-bold text-center flex justify-center text-[18px]">
                    Графік роботи
                  </span>
                </div>
                <div className="flex gap-2">
                  <div className="border-r py-1 px-3">
                    <ul className="text-black font-normal text-[18px] pr-2">
                      <li>ПН - ПТ</li>
                      <li>СБ - НД</li>
                    </ul>
                  </div>
                  <div className="py-1 px-3">
                    <ul className="text-black font-normal text-[18px]">
                      <li>09:00 - 18:00</li>
                      <li>Вихідні</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <p className="text-[15.7px]">
                <b>Працюємо по всій Україні в онлайн форматі</b>
              </p>
              <p className="text-justify">
                Офіс:{" "}
                <a
                  href="https://www.google.com/maps/place/%D0%97%D0%BE%D0%BE%D0%BF%D0%B0%D1%80%D0%BA%D0%BE%D0%B2%D0%B0%D1%8F+%D1%83%D0%BB.,+4,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4447055,30.7480845,19.73z/data=!4m6!3m5!1s0x40c633ea23bdd649:0x8530676cab4235eb!8m2!3d46.4447526!4d30.7481255!16s%2Fg%2F11c43vfq5d?entry=ttu"
                  target="black"
                  className="hover:underline"
                >
                  м. Одеса, вул. Зоопаркова, буд. 4
                </a>
              </p>
              <p>
                <b></b>Телефон:{" "}
                <a href="tel:+380968887706">
                  <b>+380 (96) 888 77 06</b>
                </a>
              </p>
              <p className="text-[18px] text-[#000] pt-2"></p>
              <ul className="flex flex-col gap-[10px] mt-10">
                <li className="flex">
                  <img src={Instagram} alt="Instagram" className="pr-[10px]" />
                  <a
                    href="https://www.instagram.com/olexandra_pushkarenko_advokat/"
                    target="blank"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Instagram
                  </a>
                </li>
                <li className="flex">
                  <img src={FaceBook} alt="Facebook" className="pr-[10px]" />
                  <a
                    href="https://www.facebook.com/share/1H8AweviJj/?mibextid=wwXIfr"
                    target="black"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Facebook
                  </a>
                </li>
                <li className="flex">
                  <img
                    src={Telegram}
                    alt="Telegram-канал"
                    className="pr-[10px]"
                  />
                  <a
                    href="https://t.me/+acXLq8a9RSljMGEy"
                    target="black"
                    className="hover:underline hover:translate-x-1 transition-transform transform"
                  >
                    Telegram-канал
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center pb-10">
            <MapContainer
              className="w-[358px] lg:w-[800px] h-[300px] lg:h-[500px] rounded-[30px] z-10 border shadow-lg  border-white "
              center={[46.4447055, 30.7480845]}
              zoom={18}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker icon={customIcon} position={[46.4447055, 30.7480845]}>
                <Popup>
                  PUSHKARENKO & PARTNERS <br />
                  <a
                    href="https://www.google.com/maps/place/%D0%97%D0%BE%D0%BE%D0%BF%D0%B0%D1%80%D0%BA%D0%BE%D0%B2%D0%B0%D1%8F+%D1%83%D0%BB.,+4,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+65000/@46.4447055,30.7480845,19.73z/data=!4m6!3m5!1s0x40c633ea23bdd649:0x8530676cab4235eb!8m2!3d46.4447526!4d30.7481255!16s%2Fg%2F11c43vfq5d?entry=ttu"
                    target="black"
                  >
                    Одеса, Зоопаркова, 4
                  </a>
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
