import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BackToTopButton } from "../button/backToTopButton";
import { BottomButtonMenu } from "../button/bottomButtonMenu";
import { isFeedBackVisibleState } from "../../recoil/atom/isFeedBackVisible";
import logo from "../../images/logo.webp";
import ModalWindow from "../modal/modalWindow";
import "./style.css";

const Header = () => {
  const location = useLocation();
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const [isVisible, setIsVisible] = useRecoilState(isFeedBackVisibleState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleToggleMenu = () => {
    setIsActiveMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsActiveMobileMenu(false);
    };

    const unlisten = () => {
      handleRouteChange();
    };

    return () => {
      unlisten();
    };
  }, [location]);

  const openModal = () => {
    setIsVisible(true);
    // setIsModalOpen(true);
    setIsActiveMobileMenu(false);
  };

  const closeModal = () => {
    // setIsModalOpen(false);
    setIsVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToAnchor = (anchorId: string) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (location.hash === "#about") {
      scrollToAnchor("about");
    }
  }, [location.hash]);

  return (
    <div className="bg-[#1F3645] min-h-[103px] w-full flex justify-around items-center z-40 px-5">
      <div className="flex justify-between max-w-[1100px] w-full z-40">
        <NavLink
          to="/"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <img
            src={logo}
            alt="Logo"
            className="lg:w-[100px] w-[70px] h-[72px]"
          />
        </NavLink>

        <div className="max-w-[812px] hidden w-full lg:flex items-center justify-center">
          <ul className="flex justify-around px-[5rem] text-[#ffffff] text-[16px] font-raleway w-full">
            <NavLink
              to={"/#about"}
              className={`font-bold hover:text-[#5DC0FF] ${
                location.pathname === "/about" ? "border-b-2 border-white" : ""
              }`}
              onClick={() => scrollToAnchor("about")}
            >
              Про мене
            </NavLink>
            <NavLink
              to={"/#team"}
              className={`font-bold hover:text-[#5DC0FF] ${
                location.pathname === "/team" ? "border-b-2 border-white" : ""
              }`}
              onClick={() => scrollToAnchor("team")}
            >
              Команда
            </NavLink>
            <NavLink
              to={"/#services"}
              className={`font-bold hover:text-[#5DC0FF] ${
                location.pathname === "/services"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={() => scrollToAnchor("services")}
            >
              Послуги
            </NavLink>
            <NavLink
              to={"/#reviews"}
              className={`font-bold hover:text-[#5DC0FF] ${
                location.pathname === "/reviews"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={() => scrollToAnchor("reviews")}
            >
              Відгуки
            </NavLink>
            <NavLink
              to={"/#contacts"}
              className={`font-bold hover:text-[#5DC0FF] ${
                location.pathname === "/contacts"
                  ? "border-b-2 border-white"
                  : ""
              }`}
              onClick={() => scrollToAnchor("contacts")}
            >
              Контакти
            </NavLink>
          </ul>
        </div>
        <div className="lg:flex justify-center items-center hidden">
          <button
            onClick={openModal}
            className="w-[300px] h-[52px] relative animate-pulse bg-[#FFFFFF] text-[#000] rounded-[32px] text-[16px] font-normal leading-[18px] font-inter transition-all duration-500 shadow-[0px_0px_12px_6px_rgba(0,0,0,0.3)] hover:opacity-[65%]"
          >
            Умови та запис на консультацію
          </button>
          {isVisible && <ModalWindow isOpen={isVisible} onClose={closeModal} />}
        </div>
        <BackToTopButton />
        <BottomButtonMenu />
        <div className="flex items-center w-[30px] lg:w-0">
          <button
            onClick={handleToggleMenu}
            className="lg:hidden flex absolute"
          >
            <input
              type="checkbox"
              id="menu_checkbox"
              className="absolute z-10 w-[30px] h-[30px]"
              checked={isActiveMobileMenu}
            />
            <label htmlFor="menu_checkbox">
              <div></div>
              <div></div>
              <div></div>
            </label>
          </button>
        </div>
      </div>
      <ul
        className={`z-30 lg:hidden block fixed top-0 left-0 right-0 bottom-0 bg-[#1F3645] transition-all duration-500 
          ${isActiveMobileMenu ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className="flex flex-col items-center pt-[7rem] text-[#fff] gap-10 text-[25px]">
          <NavLink
            to={"/#about"}
            className={`font-bold hover:text-[#5DC0FF] ${
              location.pathname === "/about" ? "border-b-2 border-white" : ""
            }`}
            onClick={() => scrollToAnchor("about")}
          >
            Про мене
          </NavLink>
          <NavLink
            to={"/#services"}
            className={`font-bold hover:text-[#5DC0FF] ${
              location.pathname === "/services" ? "border-b-2 border-white" : ""
            }`}
            onClick={() => scrollToAnchor("services")}
          >
            Послуги
          </NavLink>
          <NavLink
            to={"/contacts"}
            className={`font-bold hover:text-[#5DC0FF] ${
              location.pathname === "/contacts" ? "border-b-2 border-white" : ""
            }`}
            onClick={scrollToTop}
          >
            Контакти
          </NavLink>
          <button
            onClick={openModal}
            className="w-[300px] h-[52px] relative animate-pulse bg-[#FFFFFF] text-[#000] rounded-[32px] text-[16px] font-normal leading-[18px] font-inter transition-all duration-500 shadow-[0px_0px_12px_6px_rgba(0,0,0,0.3)] hover:opacity-[65%]"
          >
            Умови та запис на консультацію
          </button>
        </div>
      </ul>
      {isVisible && <ModalWindow isOpen={isVisible} onClose={closeModal} />}
    </div>
  );
};

export default Header;
