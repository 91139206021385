import { useEffect, useState } from "react";
import CountUp from "react-countup";

const Statistic = () => {
  const [shouldStartCounting, setShouldStartCounting] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector("li");

      if (element && window.scrollY + window.innerHeight >= element.offsetTop) {
        setShouldStartCounting(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="p-5">
      <ul className="flex flex-col items-center justify-center text-center gap-y-10 pb-14">
        <li>
          {shouldStartCounting && (
            <div className="flex justify-center items-center">
              <div>
                <CountUp
                  className="text-[28px] font-bold"
                  start={0}
                  end={8}
                  delay={0}
                />
              </div>
              <div className="text-[28px] font-bold">+</div>
            </div>
          )}
          <p className="text-[18px]">Років досвіду в юриспруденції</p>
        </li>
        <li>
          {shouldStartCounting && (
            <div className="flex justify-center items-center">
              <div>
                <CountUp
                  className="text-[28px] font-bold"
                  start={0}
                  end={4000}
                  delay={0}
                />
              </div>
              <div className="text-[28px] font-bold">+</div>
            </div>
          )}
          <p className="text-[18px]">
            Консультації з питань перетину кордону, мобілізації, військовим
          </p>
        </li>
        <li>
          {shouldStartCounting && (
            <div className="flex justify-center items-center">
              <div>
                <CountUp
                  className="text-[28px] font-bold"
                  start={0}
                  end={600}
                />
              </div>
              <div className="text-[28px] font-bold">+</div>
            </div>
          )}
          <p className="text-[18px]">Позитивних відгуків від клієнтів</p>
        </li>
      </ul>
    </div>
  );
};

export default Statistic;
