import { ReactNode } from "react";
// import Footer from "./../footer/footer";
import Header from "./../header/header";
import SubFooter from "../footer/subfooter/subfooter";
interface IPropsGeneralLayout {
  children: ReactNode;
}

const GeneralLayout = ({ children }: IPropsGeneralLayout) => {
  return (
    <>
      <header className="sticky top-0 z-40">
        <Header />
      </header>
      <main>{children}</main>
      <footer>
        {/* <Footer /> */}
        <SubFooter />
      </footer>
    </>
  );
};

export default GeneralLayout;
