import { useEffect, useState, useRef } from "react";

export const Reviews = () => {
  const reviews = Array.from({ length: 27 }, (_, index) => ({
    imageUrl: `/reviews/${index + 1}.jpg`,
  }));

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const trackRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const track = trackRef.current;
    if (!track) return;

    let start = 0;
    const totalWidth = track.scrollWidth / 2;
    const speed = 1.2;

    const animate = () => {
      start += speed;
      if (start >= totalWidth) {
        start = 0;
      }
      track.style.transform = `translateX(-${start}px)`;
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  };

  const handleMouseLeave = () => {
    const track = trackRef.current;
    if (!track) return;

    let start = parseFloat(
      track.style.transform.replace("translateX(-", "").replace("px)", "")
    );
    const totalWidth = track.scrollWidth / 2;
    const speed = 1.2;

    const animate = () => {
      start += speed;
      if (start >= totalWidth) {
        start = 0;
      }
      track.style.transform = `translateX(-${start}px)`;
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();
  };

  return (
    <>
      <div
        className="flex justify-center"
        id="reviews"
        style={{ scrollMarginTop: "120px" }}
      >
        <h1 className="text-[45px] mb-10">Відгуки</h1>
      </div>
      <div className="flex items-center overflow-hidden w-full h-[650px] relative">
        <div
          className="carousel-track flex gap-x-4"
          ref={trackRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {reviews.concat(reviews).map((review, index) => (
            <div
              className="carousel-slide flex items-center justify-center hover:scale-105 transition-transform duration-300"
              key={index}
            >
              <img
                src={review.imageUrl}
                alt={`Review ${index + 1}`}
                className="carousel-image object-cover w-[395px] h-[494px] rounded-lg shadow-lg"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
