const SubFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-[#1F3645] lg:h-[75px] h-[162px] flex lg:items-center p-5">
      <div className="flex justify-between w-full lg:px-[107px]">
        <div className="flex flex-col text-[12px] lg:text-[14px] font-light text-white">
          <p>© {currentYear} «PUSHKARENKO&PARTNERS. LAW FIRM».</p>
          <p>Усі права захищені.</p>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
