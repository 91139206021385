import { Reviews } from "./components/main/reviews";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Offer from "./components/main/offer";
import MainPage from "./components/main/main";
import Contacts from "./components/main/contacts";
import GeneralLayout from "./components/layout/GeneralLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <GeneralLayout>
        <MainPage />
      </GeneralLayout>
    ),
  },
  // {
  //   path: "/services",
  //   element: (
  //     <GeneralLayout>
  //       <Services />
  //     </GeneralLayout>
  //   ),
  // },
  {
    path: "/contacts",
    element: (
      <GeneralLayout>
        <Contacts />
      </GeneralLayout>
    ),
  },
  {
    path: "/reviews",
    element: (
      <GeneralLayout>
        <Reviews />
      </GeneralLayout>
    ),
  },
  {
    path: "/offer",
    element: (
      <GeneralLayout>
        <Offer />
      </GeneralLayout>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
